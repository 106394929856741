<template>
  <div id="app">
        {{ stateSync }}
    <router-view />
  </div>
</template>

<script>
export default {
  created() {
    var savedstate = localStorage.getItem("simpAdminState");
    if (savedstate != null) {
      this.$store.replaceState(JSON.parse(savedstate));
    }
  },
   computed: {
    stateSync() {
      var stt = this.$store.state;
      localStorage.setItem("simpAdminState", JSON.stringify(stt));
      return "";
    },
  },
}
</script>
<style lang="scss">
#app {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
