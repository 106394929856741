import axios from 'axios';
import {
    SSOService,
    SysToken,
    request,
    ServiceCandidate,
    websiteRequest
} from './request';
import jwt_decode from "jwt-decode";

function getProcessedToken(token) {
  let decoded = jwt_decode(token);
  return decoded.sub;
}
export const userLogin = (data, headers) => {
    return SSOService({
        url: `auth/realms/SimplifyCV/protocol/openid-connect/token`, // PROD
        method: "post",
        headers: headers,
        data,
    });
};

export const keyCloakLogin=(token)=>{
    // return axios.get("https://keynode.simplifycv.com/user-access/",{
     return axios.get("https://keynode.simplifymycareer.com/user-access/",{
        headers:{"Authorization" : `Bearer ${token}`}
    });
}

export const forgotPassword = (email) => {
    return SysToken({
      url: `forgot-password/`,
      method: "get",
      params:{
        email:email
      }
    });
  };

  export const registerCand = (data) => {
    return ServiceCandidate({
      url: `can/register/`,
      method: "post",
      data
    });
  };

  export const sendCandOtp = (data) => {
    return ServiceCandidate({
      url: `user/send-otp/`,
      method: "post",
      data
    });
  };

  export const getPreference = () => {
    return websiteRequest({
      url: `user-preference/`,
      method: "get",
    });
  };

  export const getPanelUuid = (data) => {
    return request({
      url: `/pan/feedback-link/`,
      method: "get",
      params: {
        uuid: data,
      },
    });
  };

  export const usernames = (username) => {
    return ServiceCandidate({
      url: `/can/check-username/`,
      method: "get",
      params:{
        username:username
      }
    });
  };

  export const googleLinkedInLogin = (data, token) => {
    return ServiceCandidate({
      url: `/can/auth/`,
      method: "post",
      data,
      headers:{"Authorization" : `Bearer ${token}`}
    });
  }

  export const checkCandOtp = (data) => {
    return ServiceCandidate({
      url: `user/check-otp/`,
      method: "get",
      params : data
    });
  };

  export const resetPassword = (data) => {
    return ServiceCandidate({
      url: `user/reset-password/`,
      method: "put",
      data
    });
  };

  export const promoCheck = (data, token) => {
    return ServiceCandidate({
      url: `/can/auth/`,
      method: "put",
      data,
      headers:{"Authorization" : `Bearer ${token}`, 'userID': getProcessedToken(token)}
    });
  }