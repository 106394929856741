import axios from "axios";
import jwt_decode from "jwt-decode";
const request = axios.create({
  baseURL:
    // "http://127.0.0.1:8000/",
    // "https://9vrcuz6g0c.execute-api.ap-south-1.amazonaws.com/dev-ass/recruitment/", // Dev Gateway
    "https://ablrb41ade.execute-api.ap-south-1.amazonaws.com/trial/recruitment/", // Staging gateway
  // "https://5ewlrd6mi7.execute-api.ap-south-1.amazonaws.com/final/recruitment/" // Production gateway
  // "http://127.0.0.1:8000/" // Local Server
});
request.interceptors.request.use(
  (config) => {
    config.headers["Authorization"] = "Bearer " + getToken();
    // config.headers["userID"] = getProcessedToken();

    return config;
  },
  (error) => {
    // Do something with request error
    return error;
  }
);
request.interceptors.response.use((response) => {
  return response;
});
//response
request.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    // Do something with request error
    if (
      error.response == undefined ||
      error.response.status == 403 ||
      error.response.status == 401
    ) {
      // await getNewAccessToken();
      error.config.headers["Authorization"] = "bearer " + getToken();
      return axios(error.config);
    } else {
      return error.response;
    }
    // if (error.response.status === 403 || error.response.status === 401) {
    // } else {
    //   return error;
    // }
  }
);
const SSOService = axios.create({
    baseURL:
    // "http://sso.simplifycv.com/", // Development
    "https://auth.simplifymycareer.com/", // Staging
  });

  const websiteRequest = axios.create({
    baseURL: "https://api-website.simplifymycareer.com",
  });
  
  websiteRequest.interceptors.request.use(
    (config) => {
      config.headers["Authorization"] = "Bearer " + getToken();
      config.headers["userID"] = getProcessedToken();
      // config.headers["userID"] = "5b935330-0f65-4492-b158-5e3e7b9b8bd4";
      // config.headers["userID"] = "e562e893-3ed8-4073-9576-10373bc3e260";
  
      return config;
    },
    (error) => {
      // Do something with request error
      return error;
    }
  );
  const getToken = () => {
    let data = document.cookie.split(";");
    for (let i in data) {
      let cur = data[i].split("=");
      if (cur[0].trim() === "accessToken") {
        return cur[1];
      }
    }
    // return localStorage.getItem("vue_token");
  };
 export const getProcessedToken =() => {
    var decoded = jwt_decode(getToken());
    return decoded.sub;
  }
  // Get token from localstorage


const SysToken = axios.create({
  baseURL:
    // "http://keynode.simplifycv.com/" // Development

    "https://keynode.simplifymycareer.com/" // Staging
});
const ServiceCandidate = axios.create({
  baseURL:
    // "http://localhost:8001/", // Development
     "https://candidate-api.simplifymycareer.com/",
    // "https://candidate-api.simplifymyc.com/",
    // "https://9vrcuz6g0c.execute-api.ap-south-1.amazonaws.com/dev-ass/candidate/", // Dev Gateway
    // "https://ablrb41ade.execute-api.ap-south-1.amazonaws.com/trial/candidate/", // Staging

});
export{
    SSOService,
    SysToken,
    ServiceCandidate,
    websiteRequest,
    request
}